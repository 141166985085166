<template>
  <div
    class="page page--main js-scrollToMap openMap--half openMap--full"
    :class="[{'messageCenterOpen' : this.$store.state.messageCenter.status}]">
    <div class="content">
      <Header
        :data="true"
        :login="true"
        :title="this.$route.matched[0].props.default.header"
        :subtitle="this.$route.matched[1] ? this.$route.matched[1].props.default.subtitle : null"
        :type="'report'"
      />
      <div class="content__inner">
        <router-view/>
      </div>
      <MessageCenter/>
      <Footer :type="'report'" :typeOfDashboard="this.$route.matched[0].props.default.dashboardType"/>
    </div>
    <ThanksPopup/>
  </div>
</template>

<script>
  import Header from '@/components/Header/Header'
  import Footer from '@/components/Footer/Footer'
  import MessageCenter from '@/components/common/MessageCenter/MessageCenter'
  import ThanksPopup from '@/components/common/modals/ThanksPopup'
  import '@/views/info/info.styl'

  export default {
    name: 'report-layout',
    components: {
      Header,
      Footer,
      MessageCenter,
      ThanksPopup,
    },
    mounted () {
      this.$store.dispatch('getAdminSubcategories')
    },
  }
</script>
